import React, { Component } from "react";
import {
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  InfiniteList,
} from "react-admin";

const PersonalList = (props) => {
  return (
    <InfiniteList {...props} exporter={false}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="nombre" />
        <TextField source="apellido" />
        <TextField source="dni" />
        <DateField source="fechaIngreso" />
        <EditButton label="Editar" />
        <DeleteButton label="Eliminar" />
      </Datagrid>
    </InfiniteList>
  );
};

export default {
  list: PersonalList,
};
