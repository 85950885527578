import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const YearMonthDropdown = ({ onYearMonthChange }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(
    String(new Date().getMonth() + 1).padStart(2, "0")
  );

  const years = Array.from(
    { length: 3 },
    (_, i) => new Date().getFullYear() + i
  ); // Genera una lista de 10 años a partir del año actual
  const months = [
    { value: "01", label: "Enero" },
    { value: "02", label: "Febrero" },
    { value: "03", label: "Marzo" },
    { value: "04", label: "Abril" },
    { value: "05", label: "Mayo" },
    { value: "06", label: "Junio" },
    { value: "07", label: "Julio" },
    { value: "08", label: "Agosto" },
    { value: "09", label: "Septiembre" },
    { value: "10", label: "Octubre" },
    { value: "11", label: "Noviembre" },
    { value: "12", label: "Diciembre" },
  ];

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    onYearMonthChange(event.target.value, selectedMonth);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    onYearMonthChange(selectedYear, event.target.value);
  };

  return (
    <>
      <Select
        value={selectedYear}
        onChange={handleYearChange}
        size="small"
        displayEmpty
      >
        <MenuItem value="" disabled>
          Seleccionar Año
        </MenuItem>
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>

      <Select
        value={selectedMonth}
        onChange={handleMonthChange}
        size="small"
        displayEmpty
      >
        <MenuItem value="" disabled>
          Seleccionar Mes
        </MenuItem>
        {months.map((month) => (
          <MenuItem key={month.value} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default YearMonthDropdown;
