import React, { useState } from "react";
import { parseISO } from "date-fns";
import { addDays } from "date-fns/fp";
import {
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Show,
  TabbedShowLayout,
  ReferenceManyField,
  ShowButton,
  NumberField,
  BooleanField,
  ReferenceField,
  CreateButton,
  useRecordContext,
  FunctionField,
  WrapperField,
  ListButton,
  TopToolbar,
  Button,
  SimpleShowLayout,
  Labeled,
  InfiniteList,
  NullableBooleanInput,
} from "react-admin";
import { supabase } from "../supabaseProvider";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import YearMonthDropdown from "../YearMonthDropdown";
const filters = [
  <NullableBooleanInput
    label="Estado"
    source="activo"
    nullLabel="Todos"
    falseLabel="Inactivos"
    trueLabel="Activos"
    alwaysOn={true}

  />,
];
const HuespedesList = (props) => {
  return (
    <InfiniteList
      {...props}
      filters={filters}      
      exporter={false}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" />
        <FunctionField
          sortable={false}
          source="nombre"
          render={(record) => `${record.nombre}, ${record.apellido}`}
        />
        <TextField source="dni" />
        <TextField source="obraSocial" />
        <DateField source="fechaIngreso" />
        <DateField source="fechaEgreso" />
      </Datagrid>
    </InfiniteList>
  );
};

const CreateMovimientoButton = () => {
  const record = useRecordContext();
  return (
    <>
      <CreateButton
        label="Registrar Pago"
        resource="movimientos"
        state={{
          record: { id_huesped: record.id, tipo: 1 },
        }}
      />
    </>
  );
};

const PreparacionFacturaComponent = () => {
  const record = useRecordContext();
  const [preparacionFactura, setPreparacionFactura] = useState({
    message: "Elegir periodo a facturar",
    error: true,
  });

  const [open, setOpen] = React.useState(false);
  const [periodo, setPeriodo] = React.useState(null);

  const previsualizarFactura = async () => {
    try {
      const { data, error } = await supabase.rpc("getpreparacionfactura", {
        id_huespedp: record.id,
        periodop: periodo,
      });
      if (data && data.length && !error) setPreparacionFactura(data[0]);
      else setPreparacionFactura({ ...error, error: true });
    } catch (error) {
      setPreparacionFactura({ error: true, message: error });
    }
  };

  const generarFactura = async () => {
    try {
      const { data, error } = await supabase.rpc("generarfactura", {
        id_huespedp: record.id,
        periodop: periodo,
      });
      if (data && data.length && !error)
        setPreparacionFactura({ error: true, message: data });
      else setPreparacionFactura({ ...error, error: true });
    } catch (error) {
      setPreparacionFactura({ error: true, message: error });
    }
  };

  const closeDialog = () => {
    setPeriodo(null);
    setPreparacionFactura({
      message: "Elegir periodo a facturar",
      error: true,
    });
    setOpen(false);
  };

  const handleYearMonthChange = (selectedYear, selectedMonth) => {
    if (selectedYear && selectedMonth)
      setPeriodo(`${selectedYear}-${selectedMonth}-01`);
  };

  return (
    <>
      <Button color="success" variant="contained" onClick={() => setOpen(true)}>
        {"Preparar Factura"}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title"> Preparacion Factura</DialogTitle>
        <DialogContent>
          <Show actions={false}>
            <YearMonthDropdown onYearMonthChange={handleYearMonthChange} />
            <Button
              color="success"
              variant="contained"
              onClick={previsualizarFactura}
              sx={{ marginLeft: "20px" }}
              disabled={!periodo}
            >
              {"Previsualizar"}
            </Button>
            <Button
              color="warning"
              variant="contained"
              onClick={generarFactura}
              sx={{ marginLeft: "20px" }}
              disabled={
                !preparacionFactura ||
                !(preparacionFactura.factura_importe_final > 0)
              }
            >
              {"Generar Factura"}
            </Button>
            <SimpleShowLayout record={preparacionFactura}>
              <Grid container columns={4} rowSpacing={1} columnSpacing={1}>
                {preparacionFactura && !preparacionFactura.error ? (
                  <>
                    <Grid item xs={3}>
                      <Labeled>
                        <TextField source="nombre_huesped" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <TextField source="tipo_pago" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <TextField source="tipofactura" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <DateField locales={"es-AR"} source="factura_desde" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={2}>
                      <Labeled>
                        <DateField locales={"es-AR"} source="factura_hasta" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <TextField source="obrasocial" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <TextField source="factura_cuit" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <NumberField
                          locales={"es-AR"}
                          source="factura_porcentaje_iva"
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                      <Labeled>
                        <NumberField
                          locales={"es-AR"}
                          source="factura_importe_final"
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={4}>
                      <Labeled>
                        <TextField
                          multiline={true}
                          source="factura_descripcion"
                        />
                      </Labeled>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="h6" align="center">
                        -- {preparacionFactura.message} --
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </SimpleShowLayout>
          </Show>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeDialog}>
            {"Cerrar"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const HuespedesShow = (props) => {
  const record = useRecordContext();
  return (
    <Show
      actions={
        <TopToolbar title="Huespedes">
          <ListButton label="Volver" />
        </TopToolbar>
      }
    >
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Datos">
          <TextField source="id" />
          <TextField source="nombre" />
          <TextField source="apellido" />
          <TextField source="dni" />
          <DateField source="fechaIngreso" />
          <DateField source="fechaEgreso" />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Facturas" path="facturas">
          <PreparacionFacturaComponent
            state={{
              record: { periodo: "2024-04-01" },
            }}
          />
          <ReferenceManyField
            reference="facturas"
            target="id_huesped"
            label={false}
          >
            <Datagrid bulkActionButtons={false}>
              <DateField locales="es-AR" source="desde" />
              <NumberField source="id" />
              <NumberField locales="es-AR" source="importe" />
              <BooleanField source="cancelada" />
              <TextField source="tipo" />
              <TextField source="cuit" />
              <TextField source="nombre" />
              <TextField locales="es-AR" source="numero" />
              <TextField locales="es-AR" source="fechaEnvio" />
              <TextField multiline={true} source="descripcion" />
              <FunctionField
                label="Fecha Pago"
                locales="es-AR"
                render={(record) => {
                  const a = addDays(60, parseISO(record.fechaEnvio));
                  return a.toLocaleDateString("es-AR");
                }}
              />
              <TextField source="notas" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Pagos" path="pagos">
          <CreateMovimientoButton />
          <ReferenceManyField
            reference="movimientos"
            target="id_huesped"
            label={false}
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="id" />
              <DateField showTime={true} Field source="fechaMovimiento" />
              <ReferenceField reference="tiposMovimiento" source="tipo">
                <TextField
                  source="nombre"
                  sx={{
                    fontVariant: "small-caps",
                    marginLeft: "4px",
                    fontWeight: "bold",
                  }}
                />
              </ReferenceField>
              <TextField source="descripcion" />
              <NumberField source="importe" />
              <TextField source="user" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default {
  list: HuespedesList,
  show: HuespedesShow,
};
